import styles from "../../styles/center.module.scss"
import React, { useState } from "react"
import Layout from "../../components/layout/layout"
import SEO from "../../components/seo"
import { graphql, useStaticQuery } from "gatsby"
import {
  RES_DATASHEET,
  RES_PAPER,
  ResourceContentMap,
} from "../../utils/constants"
import Resource from "../../components/resource/resource"
import withAppContext from "../../contexts/app.context"

const CenterPage = () => {
  const queryResult = useStaticQuery(graphql`
    {
      allMarkdownRemark(
        filter: {
          fields: { category: { eq: "resources" } }
          frontmatter: { draft: { in: [false, null] } }
        }
        sort: { fields: fields___relativePath }
      ) {
        edges {
          node {
            frontmatter {
              title
              draft
              subtype
              lang
              externalUrl
              fileName {
                name
                ext
                publicURL
              }
            }
          }
        }
      }
      seo: markdownRemark(
        fields: {
          relativePath: { eq: "resources__center" }
          category: { eq: "seo" }
        }
      ) {
        frontmatter {
          metaTitle
          metaDescription
          metaKeywordList
          metaImageName
        }
      }
    }
  `)

  const [activeTab, setActiveTab] = useState("all")

  let filteredResources = queryResult.allMarkdownRemark.edges
  const {
    seo: {
      frontmatter: {
        metaTitle,
        metaDescription,
        metaKeywordList,
        metaImageName,
      },
    },
  } = queryResult

  if (activeTab !== "all") {
    filteredResources = filteredResources.filter(edge =>
      ResourceContentMap[activeTab].includes(edge.node.frontmatter.subtype)
    )
  }

  const getExternalUrl = resource => {
    if (!!resource.fileName) {
      return `/resources/doc/${resource.fileName.name}${resource.fileName.ext}`
    }

    if (!!resource.externalUrl) {
      return resource.externalUrl
    }

    return null
  }

  return (
    <Layout>
      <SEO
        title={metaTitle}
        pageUrl={"/resources/center"}
        description={metaDescription}
        keywords={metaKeywordList}
        imageName={!!metaImageName ? `/previews/${metaImageName}` : undefined}
      />

      <div className={`${styles.resources} container volterra-container`}>
        <div className="flex-column align-items-center">
          <h1 className={styles.resources__header}>Resource Center</h1>
          <div className={styles.resources__tabs}>
            <div
              ves-e2e-test="resource-center/all-tab"
              aria-label="All Tab"
              role="button"
              onClick={() => setActiveTab("all")}
              className={`${styles.resources__tab} ${
                activeTab === "all" ? styles.resources__tabActive : ""
              }`}>
              All
            </div>
            <div
              ves-e2e-test="resource-center/datasheet-tab"
              aria-label="Datasheets Tab"
              role="button"
              onClick={() => setActiveTab(RES_DATASHEET)}
              className={`${styles.resources__tab} ${
                activeTab === RES_DATASHEET ? styles.resources__tabActive : ""
              }`}>
              Datasheets
            </div>
            <div
              ves-e2e-test="resource-center/paper-tab"
              aria-label="Papers Tab"
              role="button"
              onClick={() => setActiveTab(RES_PAPER)}
              className={`${styles.resources__tab} ${
                activeTab === RES_PAPER ? styles.resources__tabActive : ""
              }`}>
              Papers
            </div>
          </div>
        </div>

        <div
          className={`row justify-content-center justify-content-md-start ${styles.resources__wrapper}`}>
          {filteredResources.map(res => (
            <div
              ves-e2e-test={
                "resource-center/topic-type-" + res.node.frontmatter.subtype
              }
              key={res.node.frontmatter.title}
              className="col-12 col-sm-6 col-md-4 col-xl-3">
              <Resource
                title={res.node.frontmatter.title}
                type={res.node.frontmatter.subtype}
                lang={res.node.frontmatter.lang}
                externalUrl={getExternalUrl(res.node.frontmatter)}
              />
            </div>
          ))}
        </div>
      </div>
    </Layout>
  )
}

export default withAppContext(CenterPage)
